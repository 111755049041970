import { emitter } from '@/utils/emitter'

import { store } from '@/store'

export class Audio {
  constructor (autodestroy = false) {
    this.play = this.play.bind(this)
    this.pause = this.pause.bind(this)
    this.destroy = this.destroy.bind(this)
    this.reset = this.reset.bind(this)
    this.onEnd = this.onEnd.bind(this)
    this.ended = this.ended.bind(this)

    this.player = document.createElement("audio")
    this.player.pause()
    this.player.autoplay = false
    this.isPlaying = false
    this._volume = 1
    this.volume = 1
    this.autodestroy = autodestroy
    this.callbacksEnd = []

    this.player.addEventListener('ended', this.ended)

    this.unsubscribe = store.subscribe(mutation => {
      if (mutation.type === 'global/sound') {
        this.onMute(!store.state.global.sound)
      }
    })

    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'hidden') {
        this.pause()
      } else {
        this.play()
      }
    })

    this.onMute(!store.state.global.sound)
  }

  set src (src) {
    this.player.src = src

    if (this.isPlaying) {
      this.player.play()
    }
  }

  set volume (volume) {
    this._volume = volume
    this.player.volume = volume * .5
  }

  get volume () {
    return this._volume
  }

  ended () {
    this.callbacksEnd.forEach(callback => callback())
    if (this.autodestroy) {
      this.destroy()
    }
  }

  onEnd (func) {
    this.callbacksEnd.push(func)
  }

  onMute (isMute) {
    console.log('onMute', isMute)
    if (isMute) {
      this.player.muted = true
    }
    else {
      this.player.muted = false
    }    
  }

  play () {
    this.player.play().catch(error => {
      console.warn(error)
      this.ended()
    })
    this.isPlaying = true
  }

  pause () {
    this.player.pause()
    this.isPlaying = false
  }

  reset () {
    this.pause()
    this.player.currentTime = 0
  }

  destroy () {
    try {
      this.player.src = ''
      this.unsubscribe()
  
      if (this.autodestroy) {
        this.player.removeEventListener('ended', this.destroy)
      }
    } catch (error) {
      console.warn(error)
      this.ended()
    }
  }
}

export const audio = new Audio()
