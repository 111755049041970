<template>
  <div class="loader u-wrapper-panel u-row u-middle u-center u-z-middle">
    <div class="loader__svgs u-w2of12 u-w4of12--md u-w6of12--xs u-box-1by1">
      <svg class="loader__svg-base u-wrapper-panel" preserveAspectRatio="xMidYMid meet">
        <use xlink:href="#circle-small"/>
      </svg>
      <svg
        class="loader__svg-over u-wrapper-panel"
        preserveAspectRatio="xMidYMid meet"
      >
        <use xlink:href="#circle-small"/>
      </svg>
      <img class="u-wrapper-panel u-fit-contain u-pad-lg" src="/images/logo.png">
    </div>
  </div>
</template>

<script>
export default {
  props: {
    duration: {
      type: Number,
      default: 1500
    }
  },

  computed: {
    durationString () {
      return String(this.duration * .001) + 's'
    },
    shortDurationString () {
      return String(this.duration * .001 * .8) + 's'
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/easings'

duration = v-bind(durationString)
shortDuration = v-bind(shortDurationString)

.loader
  opacity 1
  background: $colors.black
  transition opacity 1s .7s linear
  
  // &:after
  //   content ''
  //   position absolute
  //   top 0
  //   left 0
  //   width 100%
  //   height 200%
  //   opacity .5
  //   transform translateY(0) translateZ(0)
  //   transition opacity 1.5s linear, transform 2s ease-in-out-quart
  //   background linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, $colors.white 50%, $colors.white 100%)

  &.custom-leave-active
    opacity 0

  &.custom-leave-active:after
    opacity 1
    transform translateY(-50%) translateZ(0)

  &__svg-base
    opacity .3
    stroke #C4C4C4

  &__svg-over
    stroke $colors.orange
    stroke-dasharray 227
    transform rotate(-90deg)
    animation: 3s ease-in-out-quad infinite around

  &__svgs
    opacity 1
    transition opacity .5s linear
  &.custom-leave-active &__svgs
    opacity 0

@keyframes around
  0%
    transform rotate(-90deg)
    stroke-dashoffset 227

  50%
    transform rotate(90deg)
    stroke-dashoffset 0

  100%
    transform rotate(270deg)
    stroke-dashoffset -227
</style>