<template>
  <app-popin class="end u-z-behind" @close="">
    <div class="u-bg-white u-radius u-bg-main-color u-second-color u-radius u-pad-xlg u-pad-l-lg u-pad-x-md--md u-pad-y-sm--md">
      <div class="u-w12of12 u-flex u-left">
        <div class="u-w7of12 u-marg-r-lg">
          <div class="t-h2 u-uppercase">
            {{ data.subtitle }}
          </div>
        </div>
        <div class="t-h3 u-pad-t-sm u-pad-t-0--sm u-w4of12 u-w5of12--sm end__paragraph" v-html="data.wysiwyg">
        </div>
      </div>
      <div class="u-row u-marg-t-lg u-marg-t-sm--sm">
        <app-button :url="data.CTA[0]?.url" :discrete="true" class="u-marg-r-md u-pad-x-0" >
          {{ data.CTA[0]?.text || 'Contact us' }}
        </app-button>
        <app-button
          @click="$ga.send('click', 'restart', '')"
          :ghost="true"
          :second="true"
          :to="$local('Introduction')"
        >
          {{ data.CTA[1]?.text || 'Restart' }}
        </app-button>
      </div>
    </div>
  </app-popin>
</template>

<script>
import AppPopin from '@/components/common/AppPopin'
import AppButton from '@/components/common/AppButton'

export default {
  components: {
    AppPopin,
    AppButton
  },

  computed: {
    data () {
      return this.$store.getters['pages/endpage']
    }
  },

  mounted () {
    this.$ga.send('load', 'end')
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'

.end
  background-image: url('/images/scene-static-blurred.jpeg')

  +mq($until: 'desktop')
    padding-top: 90px !important
    &__paragraph
      font-size 11px !important
</style>