import { createWebHistory } from 'vue-router'

import Styleguide from '@/views/Styleguide'
import Introduction from '@/views/Introduction'
import Homepage from '@/views/Homepage'
import Chapter from '@/views/Chapter'
import End from '@/views/End'
import GenericAbout from '@/views/GenericAbout'
import messages from './locales'
import { store } from '@/store'
import data from '@/assets/data'
const { data : { aboutPages } } = data
const routes = [
  {
    path: 'styleguide',
    name: 'Styleguide',
    component: Styleguide,
    meta: {
      isFull: false
    }
  },
  {
    path: '',
    name: 'Introduction',
    component: Introduction,
    meta: {
      isFull: true,
      hideHeader: true,
      hideOrientationGuard: true
    }
  },
  {
    path: 'homepage',
    name: 'Homepage',
    component: Homepage,
    meta: {
      isFull: true
    }
  },
  {
    path: 'end',
    name: 'End',
    component: End,
    meta: {
      isFull: true
    }
  },
  {
    path: ':chapter',
    name: 'Chapter',
    component: Chapter,
    meta: {
      isFull: true
    }
  },
  {
    path: ':chapter/:episode',
    name: 'Episode',
    component: Chapter,
    meta: {
      isFull: true
    }
  },
  // {
  //   path: '/:pathMatch(.*)*',
  //   name: 'NotFound', 
  //   component: NotFound
  // }
]

for (const page of aboutPages) {
  routes.push({
    path: page.slug,
    name: page.name,
    component: GenericAbout,
    meta: {
      isFull: true,
      id: page.slug,
      hideHeader: true,
      isAbout: true
    },
    beforeEnter: (to, from, next) => {
      if (!from.meta.isAbout) {
        console.log('beforeEnter', to, from)
        store.commit('global/setRouteBeforeAbout', from)
        console.log(store.state.global.routeBeforeAbout)
      }
      next()
    }
  })
}

let langRoutes = []

for (let i = 0; i < routes.length; i++)  {
  for (const locale of Object.keys(messages)) {
    const prefix = locale === 'en' ? '' : locale + '/'
    let route = {...routes[i]}
    route.path = '/' + prefix + route.path
    route.name = prefix + route.name
    route.meta = {
      ...routes[i].meta,
      locale: locale
    }

    langRoutes.push(route)
  }
}

export const router = {
  history: createWebHistory(process.env.BASE_URL || '/'),
  routes: langRoutes
}
