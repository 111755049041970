import { api } from '@/store/interfaces/apiCraft'
// import Vue from 'vue'

export default {
  namespaced: true,

  state: {
    homepage: {},
    endpage: {},
    config: {},
    loaded: false
  },

  getters: {
    homepage: state =>{
      return state.homepage
    },    
    endpage: state =>{
      return state.endpage
    },
    config: state =>{
      return state.config
    },
    loaded: state =>{
      return state.loaded
    },
    sounds: state =>{
      return {
        background: false,
        cta: false,
        true: false,
        false: false
      }
    },
  },

  actions: {
    load ({ commit, state, dispatch }) { 
      const promise = Promise.all([dispatch('homepage'), dispatch('config'), dispatch('endpage')])

      promise.then(() => {
        commit('loaded', true)
      })

      return promise
    },

    homepage ({ commit, state }) {
      return new Promise(resolve => {
        const { request, cache } = api.getHomepage()
        cache.then(res => {
          if (res !== null) {
            commit('homepage', res)
            resolve()
          }
        })

        request.then(res => {
          commit('homepage', res)
          resolve()
        })
      })
    },
    endpage ({ commit, state }) {
      return new Promise(resolve => {
        const { request, cache } = api.getEndpage()
        cache.then(res => {
          if (res !== null) {
            commit('endpage', res)
            resolve()
          }
        })

        request.then(res => {
          commit('endpage', res)
          resolve()
        })
      })
    },

    config ({ commit, state }) {
      return new Promise(resolve => {
        const { request, cache } = api.getConfig()
        cache.then(res => {
          if (res !== null) {
            commit('config', res)
            resolve()
          }
        })

        request.then(res => {
          commit('config', res)
          resolve()
        })
      })
    }
  },

  mutations: {
    homepage (state, payload) {
      state.homepage = payload
    },
    endpage (state, payload) {
      state.endpage = payload
    },
    config (state, payload) {
      state.config = payload
    },
    loaded (state, payload) {
      state.loaded = payload
    }
  }
}