
<template>
  <component
    :is="url || to.name ? 'div' : 'button'"
    class="button u-inline-block u-cursor-pointer u-center t-cta"
    :class="{
      'is-orange': orange,
      'is-white': white,
      'is-icon': icon,
      'is-disabled': disabled,
      'is-pulsing': isPulsing,
      'focus': focus,
      'is-secondary': secondary,
      'is-discrete': discrete,
    }"
  >
    <template v-if="url !== ''" >
      <a v-if="isUrlAbsolute" @focus="focus=true" @blur="focus=false" :href="url" target="_blank" class="u-relative" :class="metaClass">
        <slot/>
      </a>
      <router-link v-else @focus="focus=true" @blur="focus=false" :to="url" class="u-relative" :class="metaClass">
        <slot/>
      </router-link>
    </template>
    <router-link v-else-if="to.name" @focus="focus=true" @blur="focus=false" :to="to" class="u-relative" :class="metaClass">
      <slot/>
    </router-link>
    <span v-else class="u-relative" :class="metaClass">
      <slot/>
    </span>
  </component>
</template>

<script>
import Button from './Button'

const pulseDuration = 1000

export default {
  mixins: [Button],

  computed: {
    metaClass () {
      let classs =  this.icon ? 'u-row u-center u-middle' : ''

      classs += 'button__item u-relative u-uppercase '

      return classs + this.innerClass
    }
  },

  data () {
    return {
      focus: false,
      isPulsing: false
    }
  },

  props: {
    icon: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    orange: {
      type: Boolean,
      default: false
    },
    white: {
      type: Boolean,
      default: false
    },
    iconBig: {
      type: Boolean,
      default: false
    },
    innerClass: {
      type: String,
      default: ''
    },
    secondary: {
      type: Boolean,
      default: false
    },
    discrete: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    async doPulse () {
      if (this.disabled) {return}

      if (this.isPulsing) {
        clearTimeout(this.pulseTimeout)
        await new Promise(resolve => {
          this.isPulsing = false
          requestAnimationFrame(resolve)
        })
      }
      this.isPulsing = true
      this.pulseTimeout = setTimeout(() => {
        this.isPulsing = false
      }, pulseDuration)
    }
  },

  watch: {
    disabled () {
      if (!this.disabled) {
        this.doPulse()
      }
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'
@import '~@/styles/settings/mixins/viewport-sizer'
@import '~@/styles/settings/easings'

interPadding = 15px
interPaddingMobile = 10px

button($selector = '.button', $selectorModifier = '', $color = $colors.white, $bgColor = $colors.blue)
  if $selectorModifier is ''
    {$selector}
      z-index 0
      position relative
      color: $color
      background-color transparent
      border: solid 1px $bgColor
      border-radius 100px
      padding interPadding

      +mq($until: 'desktop')
        padding interPaddingMobile
      
      &__item
        display block
        padding 1em 2em

        // +mq($until: 'desktop')
        //   padding .7em 1.5em
      
        &:before
          position absolute
          border-radius 100px
          box-shadow 0px 0px 20px 3px $bgColor
          opacity .8
          content ''
          z-index -1
          height 100%
          width 100%
          left 0
          top 0
          transition: opacity .5s linear

      // before is used for wave effect
      // after is the background
      &:before, &:after
        content ''
        z-index -1
        position absolute
        top interPadding
        left interPadding
        right interPadding
        bottom interPadding
        border-radius 100px

      &:before
        border: 1px solid $bgColor
      
      &.is-pulsing:before
        animation 1s ease-in-out-quad 1 pulse

      &:after
        background-color: $bgColor
        transform scale(1, 1)
        transition: transform .5s ease-in-out-quart

        +mq($until: 'desktop')
          top interPaddingMobile
          left interPaddingMobile
          right interPaddingMobile
          bottom interPaddingMobile

    
    +mq($from: 'tablet')
      {$selector}
        &:hover
          &:after
            transform scale(1.13, 1.3)
        &:hover &__item:before
          opacity 0
  
  else
    {$selector}{$selectorModifier}
      color: $color
      border: solid 1px $bgColor

      {$selector}__item:before
        box-shadow 0px 0px 20px 3px $bgColor

      &:before
        border-color: $bgColor

      &:after
        background-color: $bgColor


button('.button');
button('.button', '.is-orange', $colors.white, $colors.orange);
button('.button', '.is-white', $colors.blue, $colors.white);

.button
  opacity 1
  transition opacity .3s linear

  &.is-disabled
    opacity .3
    pointer-events none

  &.is-discrete
    border: none
  &.is-secondary
    padding 0
  &.is-secondary, &.is-discrete
    color: $colors.blue
    &:after, &:before
      display none
    .button__item
      &:before
        display none
      

@keyframes pulse
  0%
    transform: translateZ(0) scale(1, 1)
    opacity 1

  100%
    transform: translateZ(0) scale(2, 2.6)
    opacity 0

</style>