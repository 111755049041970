<template>
  <div @click="$emit('close')" class="popin u-row u-center u-middle u-pad-y-xl u-pad-t-xl--md u-pad-b-sm--md u-pad-x-sm--md u-z-front">
    <div class="u-z-back u-radius u-left u-w10of12 u-w12of12--md u-full-height--sm u-overflow-scroll--sm" :class="innerClass" @click.stop="()=>{}">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    big: {
      type: Boolean,
      default: false
    },
    innerClass: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'

.popin
  position fixed
  height 100vh
  width 100%
  top 0
  left 0

  &__item
    max-height 100%
</style>


