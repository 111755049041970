<template>
  <div
    id="main"
    ref="main"
    @mousemove="onMouseMove"
    :class="{'is-full': $route.meta.isFull}"
    class="u-full-width u-relative u-overflow-hidden u-antialiased"
  >
    <symbols-svg />
    <landscape-request v-if="!$route.meta.hideOrientationGuard" />

    <all-videos v-if="loaded" />

    <router-view @openNavigation="isNavigationOpen = true" v-slot="{ Component }">
      <transition name="t-fader">
        <component v-if="loaded" :is="Component" />
      </transition>
    </router-view>

    <navigation v-if="isNavigationOpen" @close="isNavigationOpen = false" />

    <transition name="t-fader">
      <app-header v-if="!$route.meta.hideHeader" @openNavigation="isNavigationOpen = true" @toggleFullScreen="toggleFullScreen"/>
    </transition>

    <transition name="t-fader">
      <loader v-if="!loaded" />
    </transition>

    <cookie />
  </div>
</template>

<script>
import App from './App'

import SymbolsSvg from '@/components/SymbolsSvg'
import AllVideos from '@/components/AllVideos'
import LandscapeRequest from '@/components/LandscapeRequest'
import Navigation from '@/components/Navigation'
import AppHeader from '@/components/AppHeader'
import Cookie from '@/components/common/cookie'
import Loader from '@/components/common/Loader'
import data from '@/assets/data'
const { data : { aboutPages } } = data

export default {
  name: 'App',

  mixins: [App],

  components: {
    SymbolsSvg,
    AllVideos,
    LandscapeRequest,
    Navigation,
    AppHeader,
    Loader,
    Cookie
  },

  data () {
    return {
      loaded: false,
      isNavigationOpen: false
    }
  },

  created () {
    this.pointerevent = null

    Promise.all([
      this.$store.dispatch('pages/load'),
      this.$store.dispatch('data/chapters')
    ]).then(this.onInit)
    aboutPages.forEach(page => {
      this.$store.dispatch('data/genericAboutPage', page.slug)
    })
    // this.onInit()
  },

  mounted () {
    this.onResize()

    document.title = 'Bouygues Low Carbon Solutions'
  },

  methods: {
    onInit () {
      this.loaded = true
    },
    toggleFullScreen () {
      const rfs = this.$refs.main.requestFullscreen || this.$refs.main.webkitRequestFullscreen || this.$refs.main.mozRequestFullScreen || this.$refs.main.msRequestFullscreen
      const efs = document.exitFullscreen || document.webkitExitFullscreen || document.mozCancelFullScreen || document.msExitFullscreen
      if (!document.fullscreenElement) {
         rfs.call(this.$refs.main)
      } else {
        efs.call(document)
      }
    },
  }
}
</script>

<style lang="stylus" src="./styles/theme.styl"/>

<style lang="stylus">
// @import '~@/styles/settings/variables'

#main
  min-height 100vh

  &.is-full
    position fixed
    top 0
    bottom 0
    left 0
    right 0
    overflow hidden
    min-height auto

</style>