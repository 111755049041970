import { audio } from '@/utils/Audio'

export default {
  data () {
    return {
      isTransition: true,
      isWaitingforNext: false,
      isAudioEnd: true
    }
  },

  computed: {
    isNextAvailable () {
      // return true
      console.log(this.$store.state.global.transitionLoads)
      return ((this.sound && this.isAudioEnd) || (!this.sound && !this.isTransition)) && this.$store.getters['global/hasLoadedAllTransitions']
    },
    comesFromNavMenu () {
      return this.$route.query.fast === 'true'
    }
  },

  mounted () {
    this.$hub.on('transition:end', this.onTransitionEnd)
    this.$hub.on('loop:end', this.onLoopEnd)

    this.audio = audio
    this.audio.onEnd(this.onAudioEnd)
    this.onInit()
  },

  beforeUnmount () {
    this.$hub.off('transition:end', this.onTransitionEnd)
    this.$hub.off('loop:end', this.onLoopEnd)
    this.audio.muted = true
    this.audio = null
  },

  methods: {
    onInit () {
      this.isTransition = true
      this.isWaitingforNext = false

        this.audio.src = this.sound
        this.audio.currentTime = 1
        this.audio.play()
    },

    onAudioEnd () {
      this.isAudioEnd = false
      setTimeout(() => {
        this.isAudioEnd = true
      }, 0)
    },

    onTransitionEnd () {
      this.isTransition = false
    },

    onNext () {
      if (this.nextSound && this.target !== 'End') {
        this.audio.reset()
        this.audio.src = this.nextSound
        // we need to play the audio immediately to avoid iOS blocking delayed sounds
        this.audio.play()
        // every audio file begins with 1 sec of silence that we repeat until the actual transition
        // nb: "sox" utility can be used to batch this silence addition
        this.resetJob = setInterval(() => {
          this.audio.player.currentTime = 0
        }, 1000)
      }
      this.isWaitingforNext = true
      this.$hub.emit('transition:will')
      if (!this.$route.params.episode) {
        this.onLoopEnd()
      }
    },

    onLoopEnd () {
      if (this.isWaitingforNext && this.target !== 'End') {
        this.resetJob && clearInterval(this.resetJob)
        this.audio.player.currentTime = 1
        this.audio.player.play()
        this.sound = this.nextSound
        this.$router.push(this.target)
        this.isWaitingforNext = false
      }
    }
  },

  watch: {
    $route (to, from) {
      if (to.name.includes('Chapter') || to.name.includes('Episode')) {
        this.onInit()
      }
      if (to.name.includes('End')) {
        this.audio.pause()
        this.audio.muted = true
      }
    }
  }
}