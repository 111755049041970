<template>
  <video
    class="u-wrapper-panel u-fit-cover"
    autoplay
    :loop="isPlaying && !isLoop ? false : true"
    muted
    ref="video"
    :src="src"
    @ended="onEnded"
    preload="auto"
    playsinline
  />
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      default: ''
    },
    play: {
      type: Boolean,
      default: false
    },
    reset: {
      type: Boolean,
      default: false
    },
    isLoop: {
      type: Boolean,
      default: false
    },
    frameTransition: {
      type: Number,
      default: 0
    },
    autoReset: {
      type: Boolean,
      default: false
    }
  },

  created () {
    this.isPlaying = false
    this.duration = Infinity
    this.isEnding = false
    this.currentTime = 0
  },

  mounted () {
    this.video = this.$refs.video
    this.video.addEventListener('loadedmetadata', this.onVideoData)
    this.video.addEventListener('canplaythrough', () => {
      this.$emit('canPlayThrough')
    })
    if (this.play) {
      this.doPlay()
    }
    else {
      this.doPause()
    }
  },

  methods: {
    onVideoData () {
      this.duration = this.$refs.video.duration
      this.$emit('length', this.video.duration)
    },
    doPlay () {
      this.isPlaying = true
      this.video.currentTime = 0
      this.video.play()

      requestAnimationFrame(() => {
        this.$emit('play')
      })
    },
    doPause () {
      this.isPlaying = false
      this.video.pause()
      this.$emit('pause')
    },
    onUpdate ({ delta }) {
      if (this.video && this.isPlaying) {
        this.currentTime = this.video.currentTime

        const margeDuration = this.duration - delta * .001 * 5
        if (this.currentTime > margeDuration && !this.isEnding) {

          this.isEnding = true
          this.$emit('end')

          if (this.autoReset) {
            setTimeout(() => {
              this.video.currentTime = 0
            }, 300)
          }
        }
        else if (this.currentTime < margeDuration) {
          this.isEnding = false
        }
      }
    }
  },

  watch: {
    play () {
      if (this.play) {
        this.doPlay()
      }
      else {
        this.doPause()
      }
    },
    reset () {
      this.doPlay()
    }
  }
}
</script>
