<template>
  <div class="navigation u-z-front u-wrapper-panel u-row u-bg-white u-overflow-y-scroll u-invisible-scroll u-pad-y-md--sm u-pad-x-sm--sm">
    <div class="u-row u-middle u-marg-t-md u-marg-t-0--sm u-marg-b-lg u-marg-b-md--sm u-full-width u-between--md navigation__title">
      <div class="t-h2 u-marg-l-1of12 u-marg-l-0--sm u-blue u-uppercase u-main-color u-pad-r-sm u-w10of12">
        Our low-carbon solutions
      </div>
      <div class="u-w1of12 u-center">
        <app-button-square @click="$emit('close')" svg="cross" :stroke="true" />
      </div>
    </div>
    <div class="u-left u-w11of12 u-w12of12--sm u-marg-l-1of12 u-marg-l-0--sm">
      <div v-for="chapter in chapters" :key="chapter.id">
          <div class="t-h3 u-blue u-uppercase u-main-color t-black u-inline-block">
            {{chapter.title}}
          </div>
        <div class="u-full-width u-overflow-x-scroll u-invisible-scroll u-marg-t-md u-marg-t-sm--sm u-marg-b-lg">
          <div class="navigation__wrapper" :style="{width: (180 + 20) * chapter.episodes.length + 'px'}">
            <router-link
              v-for="episode in chapter.episodes"
              :key="episode.id"
              @click="onLink(episode.slug)"
              :to="{name: 'Episode', params: {chapter: chapter.slug, episode: episode.slug}, query: {fast: true}}"
              class="navigation__card u-marg-r-sm u-bg-main-color u-radius--tiny u-overflow-hidden u-bg-blue u-flex u-column"
            >
              <div class="u-box-4by3 u-box-4by5--sm">
                <app-images v-if="episode.thumbnail.length || episode.bouyguesImage.length" class="u-wrapper-panel u-fit-cover u-radius--tiny navigation__image" :images="episode.thumbnail.length ? episode.thumbnail : episode.bouyguesImage" />
              </div>
              <div class="t-text t-text--small--sm u-green u-center u-pad-y-sm u-pad-x-xs u-relative u-grow u-white u-middle u-center u-flex">
                {{ episode.title }}
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppButtonSquare from '@/components/common/AppButtonSquare'

export default {
  components: {
    AppButtonSquare
  },

  computed: {
    chapters () {
      return this.$store.getters['data/chapters']
    },
    total () {
      return this.$store.getters['data/episodes'].length
    },
  },

  mounted () {
    this.$ga.send('navigation', 'navigation', 'open')
  },

  beforeUnmount () {
    this.$ga.send('navigation', 'navigation', 'close')
  },

  methods: {
    onLink (slug) {
      this.$ga.send('click', 'navigation', 'navigation from menu', slug)
    }
  },

  watch: {
    $route () {
      this.$emit('close')
    }
  },
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'

.navigation
  &__title
    position: sticky;
    top: 0;
    left: 0;
    background: white;
    z-index: 100;
    padding: 5px;
  &__wrapper
    display flex
    flex-wrap nowrap

  &__card
    min-width 215px
    position relative
    opacity 1
    transition opacity 0.1s linear
    &:hover
      opacity 0.9
  +mq($until: 'desktop')
    &__title
      top: -35px
    &__card
      min-width initial
      width 134px
  
</style>