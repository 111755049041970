<template>
  <div class="u-wrapper-panel u-pointer-none all-videos">
    <template v-for="item in all">
        <!-- v-if="item.slug === current || item.slug === next" -->
      <transition-to-loop
        v-if="item.slug === current || item.slug === next"
        :key="item.slug"
        :loop1="item.videoLoop[0].url"
        :loop2="item.videoLoop[1]?.url"
        :transition="selectSrc(item.videoTransition)"
        :isActive="(item.slug === current || preshot) || (item.slug === next && preshot)"
      />
    </template>
  </div>
</template>

<script>
import TransitionToLoop from '@/components/TransitionToLoop'

export default {
  components: {
    TransitionToLoop
  },

  computed: {
    all () {
      return [
        {...this.$store.getters['pages/config'], slug: 'homepage'},
        ...this.$store.getters['data/episodes'],
        ...this.$store.getters['data/chapters']
      ].filter(data => {
        return data.videoLoop?.length && data.videoTransition?.length})
    },
    current () {
      if (this.$route.params.chapter && this.$route.params.episode) {
        return this.$route.params.episode
      }
      else if (this.$route.params.chapter) {
        return this.$route.params.chapter
      }
      else if (this.$route.name?.includes('Homepage')) {
        return 'homepage'
      }
    },
    next () {
      if (this.$route.params.chapter || this.$route.params.episode) {
        return this.$store.getters['data/nextElementSlug'](this.$route.params.chapter, this.$route.params.episode)
      }
      else if (this.$route.name?.includes('Homepage') && this.$store.getters['data/chapters'].length) {
        return this.$store.getters['data/chapters'][0].slug
      }
      else {
        return 'homepage'
      }

      return null
    },
    preshot () {
      return this.$route.name?.includes('Introduction')
    },
    supportsWebm () {
      return this.$device.supportsWebm
    },
  },

  data () {
    return {
      willNext: false,
      // preshot: false
    }
  },

  mounted () {
    this.$hub.on('transition:will', this.onTransitionWill)
    this.$hub.on('loop:end', this.loopEnd)
  },

  methods: {
    loopEnd () {
      if (this.willNext) {
        // this.preshot = true
      }
    },
    onTransitionWill () {
      // this.willNext = true
    },
    selectSrc (video) {
      if (this.supportsWebm && video[1] && !(this.$device.isIphone || this.$device.isIpad)) {
        return video[1].url
      }
      else {
        return video[0].url
      }
    }
  },

  watch: {
    $route () {
      // this.preshot = false
      // this.willNext = false
    }
  }
}
</script>