<template>
  <div class="u-relative">
    <div class="u-bg-black u-white t-h1">
      Colors
    </div>
    <div class="u-bg-white u-row">
      <div class="u-bg-white u-w2of12 u-box-1by1">
        <div class="u-wrapper-panel t-text u-black">
          white
        </div>
      </div>
      <div class="u-bg-black u-w2of12 u-box-1by1">
        <div class="u-wrapper-panel t-text u-white">
          black
        </div>
      </div>
      <div class="u-bg-blue u-w2of12 u-box-1by1">
        <div class="u-wrapper-panel t-text u-black">
          blue
        </div>
      </div>
      <div class="u-bg-orange u-w2of12 u-box-1by1">
        <div class="u-wrapper-panel t-text u-black">
          orange
        </div>
      </div>
      <div class="u-backdrop u-w2of12 u-box-1by1">
        <div class="u-wrapper-panel t-text u-black">
          backdrop
        </div>
      </div>
    </div>
    <div class="u-bg-black u-white t-h1">
      Size
    </div>
    <div class="u-bg-white u-row u-top">
      <div class="u-bg-orange u-relative u-w2of12 u-pad-t-xl">
        <div class="t-text u-wrapper-panel u-white">xl</div>
      </div>
      <div class="u-bg-orange u-relative u-w2of12 u-pad-t-lg">
        <div class="t-text u-wrapper-panel u-white">lg</div>
      </div>
      <div class="u-bg-orange u-relative u-w2of12 u-pad-t-md">
        <div class="t-text u-wrapper-panel u-white">md</div>
      </div>
      <div class="u-bg-orange u-relative u-w2of12 u-pad-t-sm">
        <div class="t-text u-wrapper-panel u-white">sm</div>
      </div>
      <div class="u-bg-orange u-relative u-w2of12 u-pad-t-xs">
        <div class="t-text u-wrapper-panel u-white">xs</div>
      </div>
    </div>
    <div class="u-bg-black u-white t-h1">
      Typographies
    </div>
    <div class="u-bg-white u-black">
      <div class="t-h1">t-h1</div>
      <div class="t-h2">t-h2</div>
      <div class="t-h3">t-h3</div>
      <div class="t-h4">t-h4</div>
      <div class="t-h5">t-h5</div>
      <div class="t-text">t-text</div>
      <div class="t-text--small">t-text--small</div>
    </div>
    <div class="u-bg-black u-white t-h1">
      Buttons
    </div>
    <div class="u-bg-white u-black">
      <app-button class="u-marg-r-sm">continue</app-button>
      <app-button class="u-marg-r-sm" :orange="true">continue</app-button>
      <app-button class="u-marg-r-sm" :white="true">continue</app-button>
      <app-button class="u-marg-r-sm" :disabled="true">continue</app-button>
    </div>
    <div class="u-bg-black u-white t-h1">
      Buttons round
    </div>
    <div class="u-bg-white u-black">
      <app-button-square class="u-marg-r-sm" svg="zoom" />
      <app-button-square class="u-marg-r-sm" svg="menu" :stroke="true" />
      <app-button-square class="u-marg-r-sm" :grey="true" svg="zoom" />
    </div>
    <div class="u-bg-black u-white t-h1">
      Popin
    </div>
    <div class="u-bg-white u-black">
      <app-button @click="isPopin = true">Open Popin</app-button>
    </div>
    <app-popin v-if="isPopin" @close="isPopin = false">
      <div class="t-h2 u-bg-white u-center">Popin de test</div>
    </app-popin>
    <progress-bar />
    <video-controls/>
  </div>
</template>

<script>
import AppButton from '@/components/common/AppButton'
import AppButtonSquare from '@/components/common/AppButtonSquare'
import AppPopin from '@/components/common/AppPopin'
import ProgressBar from '@/components/ProgressBar'
import VideoControls from '@/components/VideoControls'

export default {
  components: {
    AppButton,
    AppPopin,
    AppButtonSquare,
    ProgressBar,
    VideoControls
  },

  data () {
    return {
      isPopin: false
    }
  }
}

</script>