<template>
  <div class="progress-bar__wrapper u-pad-xs u-pad-sm--sm u-center u-backdrop u-white">
    <div class="t-h4">AREA {{$router.params}}</div>
    <div class="progress-bar u-center u-row u-center u-middle">
      <template v-for="(chapter, index) in chapters">
        <div
          class="progress-bar__step u-relative"
          :class="{'is-active': $route.params?.chapter === chapter.slug}"
        >
          <div class="progress-bar__step__item u-box-1by1">
            
          </div>
          <div class="progress-bar__step__title t-text--small u-uppercase">
            {{ chapter.title }}
          </div>
        </div>
        <div class="progress-bar__net" v-if="index < chapters.length - 1" />
      </template>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    chapters () {
      return this.$store.getters['data/chapters']
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/easings'
@import '~@/styles/settings/mixins/mq'


.progress-bar
  padding-bottom 25px
  margin-top 15px

  +mq($until: 'desktop')
    margin-top 10px

  &__wrapper
    position absolute
    bottom $spacings.lg
    width $width = (100*3/12)+'%'
    left (100 - $width) * .5

    +mq($until: 'desktop')
      bottom $spacings.sm
      width $widthMobile = 29%
      left (100 - $widthMobile) * .5 + 2.5
      
  &__step
    width 5.6%
    border-radius 50%
    border: solid 1px $colors.white
    
    &:before
      content ''
      top 15%
      left 15%
      right 15%
      bottom 15%
      border-radius 50%
      position absolute
      background $colors.white
      opacity 0
      transition opacity .5s linear

    &__title
      position absolute
      width 100px
      top calc(100% + 10px)
      left calc(50% - 50px)
      opacity 0
      transform translateY(-15px)
      transition opacity .5s linear, transform .5s ease-in-out-quart

    &.is-active:before
      opacity 1
      transition opacity .5s .5s linear
            
      
    &.is-active &__title
      opacity 1
      transform translateY(0)
      transition opacity .5s .7s linear, transform .5s .7s ease-in-out-quart
        

  &__net
    height 1px
    width 9%
    background-color: $colors.white
    margin 0 2%
</style>

