<template>
  <perfect-scrollbar class="u-w3of12 u-marg-l-lg u-marg-l-sm--sm u-shadow u-radius--small info-panel u-flex--sm u-column--sm" :class="[isPanelFull ? 'info-panel--full u-w12of12 u-full-height u-marg-t-0 u-marg-l-0--sm u-z-front u-relative' : '' ]">
    <app-images v-show="!isSummary" class="u-cover u-full-width info-panel__over-image" :images="data.bouyguesImage"></app-images>
    <app-button-square v-show="$device.isTablet && isPanelFull" @click="isPanelFull = false" svg="cross" :stroke="true" class="info-panel--full__close u-absolute u-z-middle" />
    <div v-show="!isSummary" class="info-panel__over-title t-h5 u-pad-x-sm u-pad-y-xs u-radius u-center" :class="[isPanelFull ? 'info-panel__over-title--corner u-absolute' : 'u-pad-x-xs--sm']" :style="{'backgroundColor': data.bouyguesOverTitleColor}">{{ data.bouyguesOverTitle }}</div>
    <div class="info-panel__split-view u-pad-sm--sm u-flex--sm" :class="[isSummary ? 'u-full-height' : 'u-marg-t-md--sm u-full-vp-width--sm']">
      <div class="info-panel__left" :class="[{'u-half-width--sm': !isSummary, 'u-flex u-middle': isSummary}]">
        <div class="u-pad-x-md u-pad-l-0--sm u-pad-t-0 u-flex u-column u-flex-row--sm u-top--sm" :class="[isSummary ? 'u-marg-t-0--sm u-pad-r-0--sm' : 'u-marg-t-0--sm u-pad-r-sm--sm u-marg-b-sm--sm']">
          <div v-show="!isSummary" class="info-panel__figure u-center u-radius u-bg-blue u-pad-sm u-marg-b-sm u-white u-flex u-middle u-marg-y-0--sm u-marg-r-md--sm u-full-width--sm u-around--sm">
            <span class="info-panel__figure__unit t-h3">
              {{ data.title }} 
            </span>
            <!-- <span class="info-panel__figure__unit t-h2">
              {{ data.bouyguesFigure }} 
            </span> -->
            <!-- <span class="info-panel__figure__details u-border-white u-pad-xs u-radius--big">
              {{ data.bouyguesFigureUnit }}
            </span> -->
          </div>
          <div class="info-panel__summary u-flex u-middle u-block--sm" :class="{'info-panel__summary--small': isSummary}">
            <div :class="{'u-flex--sm': isSummary}">
              <h3 v-show="isSummary" class="t-h3 u-uppercase u-marg-b-0 u-pad-r-xs--sm" :class="{'u-marg-b-xs--sm': isSummary}">{{ data.title }}</h3>
              <!-- <h3 v-show="!isSummary" class="t-h3 u-uppercase u-marg-b-0 u-pad-r-xs--sm">{{ data.title }}</h3> -->
              <!-- <div class="t-h3 u-blue u-marg-b-sm u-pad-r-xs--sm u-marg-b-0--sm info-panel__subtitle" :class="{'info-panel__subtitle--short': isSummary}">{{ data.bouyguesSubTitle }}</div> -->
              <app-button v-show="isSummary" :secondary="true" @click="toggleFullPanel" class="info-panel__expand" innerClass="info-panel__expand-button">See more</app-button>
            </div>
          </div>
        </div>
  
        <div v-show="!isSummary" class="info-panel__key-number u-pad-x-md u-pad-l-0--sm u-pad-r-sm--sm">
          <div class="t-h3 u-blue u-marg-b-sm u-pad-r-xs--sm u-marg-b-0--sm info-panel__subtitle" :class="{'info-panel__subtitle--short': isSummary}" v-show="data.bouyguesSubTitle">Did you know ?</div>
          <div class="t-text u-marg-b-sm u-marg-b-0--sm u-full-height--sm u-marg-b-xs--sm info-panel__content__paragraph">{{ data.bouyguesSubTitle }}</div>
          <div class="t-h3 u-blue u-marg-b-sm u-pad-r-xs--sm u-marg-b-0--sm info-panel__subtitle" :class="{'info-panel__subtitle--short': isSummary}">Let's talk engineering</div>
        </div>
        <div v-show="!isSummary" class="info-panel__content-wrapper u-pad-x-md u-pad-l-0--sm u-pad-r-sm--sm u-pad-b-md--sm u-top--sm">
          <div v-show="!isSummary" class="info-panel__content u-top--sm u-full-height--sm">
            <div v-for="(block, index) in data.bouyguesTexts" :key="index" class="t-text u-marg-b-sm u-marg-b-0--sm u-pad-l-0--sm u-full-height--sm info-panel__content__paragraph" v-html="block.paragraph"></div>
          </div>
        </div>
      </div>
      <div v-show="!isSummary" class="info-panel__right u-half-width--sm">
        <div class="info-panel__side-infos u-pad-x-md u-pad-b-md u-pad-t-0 u-pad-0--sm">
          <table v-if="data.bouyguesIndexes?.length" class="info-panel__indexes u-marg-b-sm--sm u-border-blue u-marg-t-md u-marg-b-sm u-full-width u-marg-y-0--sm">
            <thead class="info-panel__indexes__head">
              <tr>
                <th class="info-panel__indexes__cell t-text" scope="col">{{ (data.bouyguesIndexes[0].text) }}</th>
                <th v-if="data.bouyguesIndexes[1]" class="info-panel__indexes__cell t-text" scope="col">{{ (data.bouyguesIndexes[1]?.text) }}</th>
                <th v-if="data.bouyguesIndexes[2]" class="info-panel__indexes__cell t-text" scope="col">{{ (data.bouyguesIndexes[2]?.text) }}</th>
              </tr>
            </thead>
            <tbody class="info-panel__indexes__body">
              <tr>
                <td class="info-panel__indexes__cell info-panel__indexes__cell--img t-text">
                  <app-images v-for="i in data.bouyguesIndexes[0].number" :key="i" :images="data.bouyguesIndexes[0].picto" class="u-marg-x-xxs">
                  </app-images>
                </td>
                <td v-if="data.bouyguesIndexes[1]" class="info-panel__indexes__cell info-panel__indexes__cell--img t-text">
                  <app-images v-for="i in data.bouyguesIndexes[1]?.number" :key="i" :images="data.bouyguesIndexes[1]?.picto" class="u-marg-x-xxs">
                  </app-images>
                </td>
              </tr>
            </tbody>
          </table>
          <table v-else-if="data.bouyguesIndex?.length" class="info-panel__indexes u-marg-b-sm--sm u-border-blue u-marg-t-md u-marg-b-sm u-full-width u-marg-y-0--sm">
            <thead class="info-panel__indexes__head">
              <tr>
                <th class="info-panel__indexes__cell t-text" scope="col">{{ (data.bouyguesIndex[0].col1) }}</th>
                <th class="info-panel__indexes__cell t-text" scope="col">{{ (data.bouyguesIndex[1]?.col1) }}</th>
              </tr>
            </thead>
            <tbody class="info-panel__indexes__body">
              <tr>
                <td class="info-panel__indexes__cell t-text">{{ (data.bouyguesIndex[0].col2) }}</td>
                <td class="info-panel__indexes__cell t-text">{{ (data.bouyguesIndex[1]?.col2) }}</td>
              </tr>
            </tbody>
          </table>
          <div class="info-panel__important-wrapper u-full-height--sm">
            <div class="u-blue t-h3">{{ (data.bouyguesImportantTitle) }}</div>
            <div class="info-panel__important u-pad-sm u-marg-t-lg u-pad-t-lg u-flex u-middle u-radius">
              <div class="info-panel__important__image u-box-1by1">
                <app-images :images="data.bouyguesImportantImage" class="u-round u-fit-cover u-bg-white u-absolute"></app-images>
              </div>
              <div class="u-marg-l-0 u-full-width u-grow t-text--small info-panel__important__text" v-html="data.bouyguesImportantText"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </perfect-scrollbar>
</template>

<script>
import AppImages from './common/AppImages.vue';
import AppButton from './common/AppButton.vue';
import AppButtonSquare from './common/AppButtonSquare.vue';
import { PerfectScrollbar } from 'vue3-perfect-scrollbar'

  export default {
    name: 'InfoPanel',
    components: {
      AppImages,
      AppButtonSquare,
      AppButton,
      PerfectScrollbar
    },
    props: {
      data: {
        type: Object,
        default: () => { return {} }
      },
      entry: {
        type: Object,
        default: () => { return {} }
      },
    },
    data () {
      return {
        isPanelFull: false,
      }
    },
    computed: {
      isSummary () {
        return this.$device.isTablet && !this.isPanelFull;
      },
      // index () {
      //   let dataObj = this.data.bouyguesIndex;
      //   if (this.data.bouyguesIndexes?.legnth) {
      //     dataObj = this.data.bouyguesIndexes;
      //     dataObj = dataObj.map((item) => {
      //       return {
      //         col1: item.text,
      //         col2: item.value
      //       }
      //     })
      //   }
      // }
    },
    methods: {
      toggleFullPanel () {
        this.$ga.send('click', 'see more', this.$route.path)
        this.isPanelFull = true
      }
    },
  }
</script>

<style src="vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css"/>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'

.ps--active-y > .ps__rail-y
  opacity: 0.6 !important; 
  background-color: rgba(6, 169, 184, 0.4); 
  width: 10px !important; border-radius: 20px;
.ps--active-y > .ps__rail-y > .ps__thumb-y 
  background-color: #06A9B8 !important;

.info-panel
  height calc(100% - 210px)
  margin-top 160px
  background: rgba(255, 255, 255, 0.8)
  box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.15)
  backdrop-filter: blur(17px)
  overflow-y scroll


  // position relative
  // &::after
  //   content ''
  //   position sticky
  //   top 0
  //   right 0
  //   width 10px
  //   height 20px
  //   background: blue
  //   z-index 1
  // &::before
  //   content ''
  //   position sticky
  //   bottom 0
  //   right 0
  //   width 10px
  //   height 70%
  //   background: aquamarine
  //   z-index 1
  &__over
    &-image
      // max-height max(20%, 9vw)
      height 9vw
      border-radius 10px 10px 30px 30px
      object-fit cover
      position sticky
      top 0
      left 0
      z-index 1
    &-title
      position sticky
      transform translateY(-70%)
      margin auto
      width max-content
      margin-bottom -20px
      max-width 95%
      top calc(9vw + 5px)
      left 0
      z-index 2

  &__figure
    
    &__details
      border: 1px solid $colors.white;
    &__unit
      text-shadow: 3px 3px 0px #07909C;

  &__indexes
    border-spacing: 0px;


    &__head
      td, th
        &:first-of-type
          border-top: 2px solid $colors.blue
          border-left: 2px solid $colors.blue
          border-bottom: 1px solid $colors.blue
          border-right: 1px solid $colors.blue
          border-top-left-radius: $spacings.xs
        &:last-of-type
          border-top: 2px solid $colors.blue
          border-right: 2px solid $colors.blue          
          border-bottom: 1px solid $colors.blue
          border-left: 1px solid $colors.blue
          border-top-right-radius: $spacings.xs
    &__body
      td, th
        &:first-of-type
          border-bottom: 2px solid $colors.blue
          border-left: 2px solid $colors.blue
          border-top: 1px solid $colors.blue
          border-right: 1px solid $colors.blue
          border-bottom-left-radius: $spacings.xs
        &:last-of-type
          border-bottom: 2px solid $colors.blue
          border-right: 2px solid $colors.blue
          border-top: 1px solid $colors.blue
          border-left: 1px solid $colors.blue
          border-bottom-right-radius: $spacings.xs
    
    &__cell
      padding: $spacings.xs $spacings.sm
      text-align: center
      &--img
        img
          max-height: 30px

  &__important
    background-color: rgba(6, 169, 184, 0.4);
    position relative
    padding-top: 45px !important
    &__image
      position absolute !important
      min-width 60px
      top -20%
      left calc(50% - 30px)
      img
        top 0

  &--full
    // take priority over the summary version's margin
    &.u-marg-l-sm--sm
      margin-left: 0

    &__close
      top 15px
      right 40px
      position sticky !important
      margin-left auto
      margin-top: -65px
    .info-panel__over-title
      margin-left 0
      margin-top: -35px;

    .t-h3, .t-h5
      &:not(.info-panel__over-title)
        font-size 21px
        line-height 30px
        margin-bottom 10px
  +mq($until: 'desktop')
    margin-top: 130px
    height calc(100% - 150px)

    .t-text--small
      font-size 14px !important

    &__over
      &-image
        height 80px
      &-title
        position sticky !important
        font-size 9px
        line-height 13px
        padding-top 5px !important
        padding-bottom 5px !important
        margin-bottom: -10px
        margin-top 4px
        top calc(80px + 5px)
        &--corner
          top: 35px
          left: 20px
          margin-top: -35px
    &__figure
      &__unit.t-h2
        font-size: 18px
      &__details
        font-size: 12px
    &__summary
      &--small
        // override font size in summary mode according to design
        .t-h3
          font-size 11px
          line-height 16px
    &__content
      // width: max-content
      // display: flex
      // flex-direction: row
      & > div, table
        padding: 0 15px 0 0
      &-wrapper
        overflow auto
        // prevent weird scroll

    &__expand
      display: inline;
      height: min-content;
      margin-top: auto;
      margin-bottom: auto;
      margin-left: auto;
      &-button
        padding 7px 15px
        white-space nowrap

    &__indexes, &__content__paragraph
      max-width 55vw
    // &__important
    // //   // width: max-content;
    //   &__text
        // width: max-content;
        // max-width: 275px;

    &__subtitle--short
      display: -webkit-box;
      max-width: 50%;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
</style>
