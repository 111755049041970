<template>
  <div class="introduction u-main-color u-wrapper-panel u-flex u-column u-left u-pad-t-md u-middle u-left--md u-middle--md u-pad-t-lg--md u-pad-b-xlg u-pad-b-0--md u-center--xs">
    <div class="u-wrapper-panel">
      <img src="/images/scene-static.webp" class="u-fit-cover" />
      <div class="u-backdrop u-wrapper-panel" />
    </div>
    <!-- <div v-if="$device.isTablet && !$device.isMobile" class="u-marg-b-xs">
    </div> -->
    <!-- <img v-if="$device.isTablet && !$device.isMobile" class="u-center u-block introduction__logo--tablet" src="/images/logo.png"> -->
    <img v-if="!$device.isMobile" class="u-center u-block u-z-back u-w1of12 u-pad-b-sm" src="/images/logo.png">
    <div class="u-w11of12 u-center--md u-left t-h1 u-relative u-white introduction__title u-flex--md u-full-width--md u-wrap u-column--xs u-wrap u-left--xs u-pad-l-sm--xs introduction__content">
      <div v-if="$device.isMobile" class="u-marg-b-md">
        <img class="u-w4of12 u-block" src="/images/logo.png">
      </div>
      <div class="u-row u-middle u-marg-r-sm--md u-marg-r-0--xs u-center u-left--xs">
        OUR LOW
        <!-- <img v-if="!$device.isTablet" class="introduction__logo u-marg-l-1of12" src="/images/logo.png"> -->
      </div>
      <div class="u-marg-r-sm--md u-marg-r-0--xs u-center u-left--xs">
        CARBON
      </div>
      <div class="u-row u-middle u-top--md u-between--sm u-middle--xs u-marg-r-0--md u-center u-left--xs">
        SOLUTIONS
        <!-- <div v-if="!$device.isTablet" class="u-marg-l-1of12 u-marg-l-0--md u-marg-l-1of12--xs u-w3of12 t-h3 introduction__subtitle--wide">
          {{ data.subtitle }}
        </div> -->
      </div>
      <div class="u-marg-t-md t-h4 u-pad-r-2of12--xs u-center u-bold u-left--xs">
        {{ data.subtitle }}
      </div>
      <div v-if="$device.isMobile" @click="onContinue" class="u-marg-t-md">
        <app-button :to="{name: 'Homepage'}">
          {{ data.CTALabel }}
        </app-button>
      </div>
    </div>
    <div v-if="!$device.isMobile" @click="onContinue" class="introduction__cta u-full-width u-center u-left--xs">
      <app-button :to="{name: 'Homepage'}">
        {{ data.CTALabel }}
      </app-button>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    data () {
      return this.$store.getters['pages/homepage']
    }
  },

  methods: {
    onStart () {
      // this.$hub.emit('audio:background')
      // this.$ga.send('navigation', 'introduction', 'next')
    },
    onContinue () {
      this.$ga.send('click', 'Accueil', 'Click sur le CTA "Continue"')
    }
  },
  mounted () {
    this.$ga.send('load', 'Accueil', 'Affichage de la HP')
  }
}  
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/viewport-sizer'
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'

.introduction
  &__logo
    display block
    width .9em

    &--tablet
      z-index: 1;
      position: absolute;
      right: 20px;
      width: 50px;
      top: 20px;

  &__cta
    position absolute
    width 100%
    viewportSizer('bottom', 60, 1920, 30px)
  // +mq($until: 'mobile')
  //   &__content
  //     width: min-content !important
  +mq($from: 'mobile', $until: 'desktop')
    padding-top 7% !important
    &__title
      font-size 12vh !important
      line-height 17vh !important
    &__logo
      width 1.5em
      margin-left: auto !important;
      margin-right: 23%;
    &__subtitle--wide
      width: max-content !important;
</style>