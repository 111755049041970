<template>
  <div v-if="!choosen">
    <div class="cookie u-pad-md u-row u-bottom u-z-middle u-wrapper-panel--sm u-pointer-none">
      <div class="cookie__wrapper u-white u-backdrop u-pad-x-md u-pad-y-sm u-pad-y-md--sm u-full-width u-pointer-all">
        <div class="u-right t-text u-marg-b-sm u-cursor-pointer" @click="onDecline">
          <span class="cookie__small">
            Continuer sans accepter
          </span>
        </div>
        <div class="t-h3 u-left">
          Nous utilisons des cookies
        </div>
        <div class="u-marg-t-sm u-marg-t-sm--sm">
          <checkbox-field
            :default="true"
            name="Fonctionnels"
            slug="fonctionnals"
            class="u-pointer-none u-marg-t-xs"
          />
          <checkbox-field
            v-for="cookie in cookies"
            @onChange="value => {cookie.value = value}"
            :default="cookie.value"
            :name="cookie.name"
            :slug="cookie.slug"
          />
        </div>
        <div class="u-marg-t-sm u-center">
          <app-button @click="onValidate" :main="true" :isSmall="true">
            Valider
          </app-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CheckboxField from './CheckboxField'
import AppButton from '@/components/common/AppButton'

import { cookie } from '@/utils/storage'

export default {
  components: {
    CheckboxField,
    AppButton
  },

  data () {
    return {
      isMore: false,
      choosen: false,
      isAnalytics: true,
      analytics: false,
      cookies: [
        {name: 'Google Analytics', slug: 'analytics', value: true},
        // {name: 'Youtube', slug: 'youtube', value: true}
      ]
    }
  },

  created () {
    this.isAnalyticsSet = false
    const res = cookie.getItem(`consent`)

    if (cookie.getItem(`consent`) === 'true') {
      this.choosen = true
    }

    this.getValues()
  },

  mounted () {
    this.$hub.on('cookie:open', this.onOpen)
    this.$hub.on('cookie:service', (service, value) => {
      const c = this.cookies.find(c => c.slug === service.slug)
      if (!c) {return}
      cookie.setItem(`consent_${c.slug}`, value ? 'true' : 'false')
      this.$hub.emit('cookie:update')
    })
    this.addAnalytics()
  },

  beforeUnmount () {
    this.$hub.off('cookie:open', this.onOpen)
  },

  methods: {
    onOpen () {
      this.choosen = false
      cookie.removeItem(`consent`)
      this.getValues()
    },
    onDecline() {
      cookie.setItem(`consent`, true)
      this.choosen = true
      this.$hub.emit('cookie:update')

      this.cookies.forEach(c => {
        cookie.setItem(`consent_${c.slug}`, 'false')
      })
    },
    onValidate() {
      cookie.setItem(`consent`, true)
      this.choosen = true
      this.$hub.emit('cookie:update')

      this.setValues()
      this.addAnalytics()
    },
    getValues () {
      this.cookies.forEach(c => {
        c.value = cookie.getItem(`consent_${c.slug}`) === 'true' ? true : false
      })
    },
    setValues () {
      this.cookies.forEach(c => {
        cookie.setItem(`consent_${c.slug}`, c.value ? 'true' : 'false')
      })
    },
    addAnalytics() {
      if (cookie.getItem(`consent_analytics`) === 'true' && !this.isAnalyticsSet) {
        this.isAnalyticsSet = true

        let script = document.createElement('script')
        script.setAttribute('async', 'true')
        script.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=' + process.env.VUE_APP_GOOGLE_ANALYTICS)
        document.head.appendChild(script)
        window.dataLayer = window.dataLayer || []
        window.gtag = function gtag(){window.dataLayer.push(arguments)}
        gtag('js', new Date())
        gtag('config', process.env.VUE_APP_GOOGLE_ANALYTICS, { 'anonymize_ip': true })

        this.$hub.emit('analytics:enable')
      }
    }
  },

  watch: {
    $route (to, from) {
      if (from.name && !this.choosen) {
        if (this.onAccept) this.onAccept()
      }
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'

.cookie
  position absolute
  bottom: 0
  left 0
  width 400px

  &__small
    font-size .8em

  &__wrapper
    width 300px

    +mq($until: 'tablet')
      width 100%
</style>