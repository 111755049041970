<template>
  <component
    :is="to.name ? 'router-link' : url !== '' ? 'a' : 'div'"
    :to="to"
    :href="url"
    :target="isUrlAbsolute ? '_blank' : ''"
    class="button-round u-inline-block u-relative"
    :name="name"
    :class="{ 'is-grey': grey }"
  >
    <div class="button-round__before" :class="[{'u-backdrop': grey || transparent, 'u-backdrop--blue': transparent, 'u-bg-blue': !grey && !transparent}]" />
    <div class="u-box-1by1 u-cursor-pointer">
      <div class="button-round__wrapper u-wrapper-panel">
        <svg
          class="u-full-width u-full-height"
          :class="stroke ? `u-stroke-white` : `u-fill-white`"
          preserveAspectRatio="xMidYMid meet"
        >
          <use :xlink:href="'#' + svg"/>
        </svg>
      </div>
    </div>
  </component>
</template>

<script>
import Button from './Button'

export default {
  mixins: [Button],

  props: {
    grey: {
      type: Boolean,
      default: false
    },
    stroke: {
      type: Boolean,
      default: false
    },
    svg: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    transparent: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/mixins/mq'
@import '~@/styles/settings/variables'
@import '~@/styles/settings/easings'

.button-round
  width 70px

  &__before
    position absolute
    top 0
    left 0
    height 100%
    width 100%
    transition: transform .5s ease-in-out-quart

  // &.is-ghost:before
  //   border: solid 2px $colors.main-color
  //   background-color: transparent
  // &.is-ghost:focus:before
  //   border: solid 4px $colors.main-color
      

  +mq($until: 'tablet')
    width 55px
  
  &__wrapper
    padding 30%

    +mq($until: 'tablet')
      padding 25%
  

+mq($from: 'tablet')
  .button-round:hover
    .button-round__before
      transform scale(1.1, 1.1)

</style>