<template>
  <div class="u-wrapper-panel">
    <transition name="t-fader">
      <app-popin v-if="isPopin" innerClass="u-center" class="u-center u-bg-halfBlack  u-z-middle u-pad-t-md--sm u-full-height--sm">
        <div class="t-h4 u-full-height--sm u-radius u-shadow u-bg-white u-pad-y-lg u-pad-y-md--sm homepage__tuto u-left u-relative u-flex u-column">
          <div class="u-center u-marg-b-sm homepage__tuto__close">
            <app-button-square @click="closePopin" svg="cross" stroke="true" />
          </div>
          <h1 class="t-h2 u-pad-x-xl u-pad-x-lg--sm">Welcome to our sustainable concept hotel! </h1>
          <p class="u-pad-x-xl u-pad-x-lg--sm">It includes a large number of low-carbon solutions that we can implement on any project. The first recipient of such devices - beyond the environment of course? You. Climate-positive solutions increase the attractiveness of buildings: they facilitate access to green financing and are favored by clients or tenants who are increasingly concerned about environmental issues. We tell you more about the solutions we offer!</p>
        </div>
      </app-popin>
    </transition>
    <video-controls :isNext="isNextAvailable" @onNext="onNext" button-label="START"/>
  </div>
</template>

<script>
import PlayerPage from './PlayerPage'

import VideoControls from '@/components/VideoControls'
import AppButtonSquare from '@/components/common/AppButtonSquare'
import AppPopin from '@/components/common/AppPopin'

export default {
  mixins: [PlayerPage],

  components: {
    VideoControls,
    AppButtonSquare,
    AppPopin,
  },

  data () {
    return {
      isPopin: true
    }
  },

  computed: {
    data () {
      return this.$store.getters['pages/config']
    },
    sound () {
      return this.data.sound.length ? this.data.sound[0].url : null
    },
    nextSound () {
      const [firstChapter] = this.$store.getters['data/chapters']

      return firstChapter?.sound.length ? firstChapter.sound[0].url : null
    },
    target () {
      return {
        ...this.$local('Chapter'), params: { chapter: this.$store.getters['data/chapters'][0].slug }
      }
    }
  },
  methods: {
    // onNext () {
    //   this.$router.push(this.target)
    // },
    closePopin () {
      this.isPopin = false
      if (this.data.ambientSound.length) {
        if (window.ambiance) window.ambiance.pause()
        const ambiance = window.ambiance = new Audio(this.data.ambientSound[0].url)
        ambiance.loop = true
        ambiance.play()
        this.$store.subscribe(mutation => {
          if (mutation.type === 'global/sound') {
            ambiance.muted = !this.$store.state.global.sound
          }
        })
        document.addEventListener('visibilitychange', () => {
          if (document.visibilityState === 'hidden') {
            ambiance.pause()
          } else {
            ambiance.play()
          }
        })
      }
    }
  },
  mounted () {
    if (this.sound) {
      this.audio.reset()
      this.audio.src = this.sound
      this.audio.play()
    }
    setTimeout(() => {
      this.isTransition = false
    }, 3000)
    // this.$store.getters['pages/config'].sound[0].url
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'

.homepage
  &__btn
    position absolute
    bottom $spacings.sm
    left 50%
    transform translateX(-50%)
  &__tuto
    margin: auto
    max-height: 70vh
    overflow: auto
    width: 80vw
    &__close
      top: -40px
      transform: scale(0.5)
      position: sticky;
      margin-top: 0;
      right: -30px;
      margin-right: -50px;
      margin-left: auto;
      left: 100%;
      align-self: flex-end;
      height: min-content;
      margin-top: -80px;
      padding-top: 0;
      margin-right: 0px;

  +mq($until: 'desktop')
    &__tuto
      max-height: initial

</style>


