<template>
  <div :style="{opacity: isActive ? 1 : 0}" class="u-wrapper-panel transition-to-loop">
    <video-player
      :isLoop="false"
      :play="isActive && !hideTransition"
      :src="transition"
      @end="onTransitionEnd"
      :autoReset="true"
      :style="{zIndex: hideTransition ? -2 : 'initial'}"
      @canPlayThrough="$store.commit('global/pendingTransitionLoads', { id: transition, value: true })"
    />
    <video-player
      :isLoop="true"
      :style="{opacity: hideTransition ? loopFader : 0}"
      :play="isActive"
      :reset="!isTransition"
      :src="loop1"
      :muted="loopMuted"
      @end="onLoopEnd"
      @play="onLoopPlay"
      @length="(e) => this.fadeTimeout = e * 900"
    />
    <video-player
      v-if="loop2"
      :isLoop="true"
      :style="{opacity: hideTransition ? 1 : 0, zIndex: -1 }"
      :play="isActive"
      :reset="!isTransition"
      :src="loop2"
      :muted="loopMuted"
      @end="onLoopEnd"
      @play="onLoopPlay"
    />
  </div>
</template>

<script>
import VideoPlayer from '@/components/common/VideoPlayer'
import gsap from 'gsap'

export default {
  data () {
    return {
      isTransition: true,
      hideTransition: false,
      loopMuted: false,
      loopFader: 1,
      fadeTimeout: 3000
    }
  },

  props: {
    transition: {
      type: String,
      default: ''
    },
    loop1: {
      type: String,
      default: ''
    },
    loop2: {
      type: String,
      default: ''
    },
    isActive: {
      type: Boolean,
      default: false
    }
  },

  components: {
    VideoPlayer
  },

  created () {
    this.$store.commit('global/pendingTransitionLoads', { id: this.transition, value: false})
  },

  methods: {
    onLoopPlay () {
      // shitty fix to prevent from behing fire at the beggining
      if (!this.isTransition) {
        this.hideTransition = true
      }
    },
    onTransitionEnd () {
      if (!this.isActive) return
      this.isTransition = false
      this.$hub.emit('transition:end')
      const timeout = this.fadeTimeout
      console.log(this.fadeTimeout)
      setTimeout(() => {
        if (this.loop2) {
          gsap.to(this.$data, {
            loopFader: 0,
            duration: 0.5,
            ease: 'power2.inOut'
          })
        }
      }, timeout)
    },
    onLoopEnd () {
      this.loopMuted = true
      this.$hub.emit('loop:end')
    }
  },
  watch: {
    isActive (val) {
      if (!val) {
        this.isTransition = true
        this.hideTransition = false
        this.loopMuted = false
      }
    }
  }
}
</script>