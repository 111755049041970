<template>
  <div>
    <div class="chapter-controls u-backdrop u-z-back">
      <div class="u-wrapper-panel u-row u-bottom u-right u-pad-b-lg u-pad-r-md u-pad-b-xs--md u-pad-r-xs--md">
        <div class="u-row u-middle u-right u-full-width">
          <sound @click="onSoundClick" class="chapter-controls__sound u-marg-r-md u-marg-l-xs--sm u-pad-t-xs--sm" />
          <app-button class="u-marg-t-xs--sm" :disabled="!isNext" @click="$emit('onNext')">
            {{ buttonLabel || 'CONTINUE' }}
          </app-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sound from '@/components/Sound'

export default {
  props: {
    isNext: {
      type: Boolean,
      default: true
    },
    buttonLabel: {
      type: String,
      default: ''
    }
  },

  components: {
    Sound
  },

  methods: {
    onSoundClick () {
      this.$ga.send('click', 'sound', 'Clic sur le picto son')
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'

.chapter-controls
  position absolute
  bottom 0
  right 0
  width 340px
  clip-path: url('#svg-controls')

  +mq($until: 'desktop')
    clip-path: url('#svg-controls-mobile')

  +mq($until: 'tablet')
    width 27%
    height @css{min(68%, 220px)}

  +mq($from: 'tablet', $until: 'desktop')
    width 22%

  &:before
    content ''
    display block
    padding-top 75%

    +mq($until: 'desktop')
      padding-top 100%
  
  &__sound
    width 10%

</style>