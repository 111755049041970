<template>
  <div class="about u-flex u-column u-full-height u-bg-white u-full-height--sm u-flex--sm u-column--sm u-invisible-scroll">
    <div :style="`background-image: url(${data.image?.[0]?.url});`" class="about__header u-full-width u-flex u-right u-top u-pad-lg">
      <app-button-square @click="close" svg="cross" :stroke="true"/>
    </div>
    <div class="about__content u-row u-column-r--sm u-between u-full-width u-pad-lg u-pad-sm--sm u-grow u-overflow-scroll">
      <div class="u-w4of12 u-w12of12--sm u-marg-l-1of12 u-marg-l-0--sm u-flex--sm u-column--sm u-grow--sm">
        <h1 class="t-h2 u-marg-t-0 about__title">{{ data.subtitle ?? data.title }}</h1>
        <div ref="wysiwyg" v-html="data.wysiwyg" class="about__text u-grow--sm" />
      </div>
      <div class="u-w3of12 u-w12of12--sm u-between--sm u-marg-r-1of12 u-marg-r-0--sm u-flex u-column u-flex-row--sm">
        <div class="u-net u-bg-black u-full-width u-none--sm"></div>
        <h2 class="t-h3 u-marg-y-lg u-none--sm">{{ 'More on us' }}</h2>
        <router-link v-for="page in aboutPages" :key="page.slug" :to="{ name: page.name }" class="u-marg-b-sm u-uppercase t-h4">{{ page.name }}</router-link>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch, inject, onUpdated } from 'vue';
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import AppButtonSquare from '@/components/common/AppButtonSquare'
import _data from '@/assets/data'
const { data : { aboutPages } } = _data

const wysiwyg = ref([])

const route = useRoute()
const router = useRouter()
const store = useStore()
const ga = inject('ga')

const data = computed(() => store.state.data[route.meta.id])

const close = () => {
  router.push(store.state.global.routeBeforeAbout)
}

const mapBodyClasses = (elList) => {
  [...elList].forEach(el => {
    if (el.tagName === 'H1') {
      el.classList.add('t-h3', 'u-bold')
    }
    if (el.tagName === 'P') {
      el.classList.add('t-text')
      el.classList.add('u-pad-r-xs--sm')
    }
  })
}

onMounted(() => {
  ga.send('load', route.meta.name, `Affichage de la page ${route.meta.name}`)
  mapBodyClasses(wysiwyg.value.children)
  watch(
    wysiwyg.value.children, 
    (elList) => {
      mapBodyClasses(elList)
    }
  )

  setTimeout(() => {
    const aboutText = document.querySelector('.about__text')
    console.log(aboutText.clientHeight)
    aboutText.style.maxHeight = (aboutText.clientHeight * 0.6) + 'px'
  }, 500);
})

onUpdated(() => {
  mapBodyClasses(wysiwyg.value.children)
  const aboutText = document.querySelector('.about__text')
  aboutText.style.maxHeight = 'initial'
  setTimeout(() => {
    console.log(aboutText.getBoundingClientRect().height)
    aboutText.style.maxHeight = (aboutText.getBoundingClientRect().height * 0.6) + 'px'
  }, 500);

  // aboutText.style.maxHeight = '0px'
  // const aboutText = document.querySelector('.about__text')
  // aboutText.style.maxHeight = (aboutText.innerHTML.length / 2.5) + 'px'
  // // aboutText.style.maxHeight = (aboutText.getBoundingClientRect().height / 2) + 'px'
})
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'
@import '~@/styles/settings/mixins/responsive'
.about
  &__header
    background-size: cover
    background-image: attr(data-background)
    height: 30vh
  &__text
    h1, h2, h3, h4, h5, h6
      margin-top: 20px
      margin-bottom: 20px
  +mq($until: 'desktop')
    height: 85vh
    overflow auto
    &__header
      max-height: 70px
    &__content
      flex-wrap: nowrap !important
      flex-direction: column-reverse !important
      &.u-column-r--sm
        flex-direction: column-reverse
        align-items: center
    &__text
      display: flex
      width: auto
      // overflow: auto
      flex-direction: column
      flex-wrap: wrap
      max-height: 900px
      & > *
        max-width: 40% !important
      & > h1
        display: none
      & > p,h1,h2
        max-width: 100%
      min-width: 40vw
    &__title
      font-size: 22px !important
</style>