import { storage, cookie } from '@/utils/storage'

export default {
	namespaced: true,

	state: {
		score: 0,
		isFistVisit: true,
		sound: true,
		isTutoSeen: cookie.getItem('tuto_seen') === 'true' ? true : false,
		routeBeforeAbout: null,
		transitionLoads: {}
	},

	getters: {
		score (state) {
			return state.score
		},
		isTutoSeen(state) {
			return state.isTutoSeen
		},
		hasLoadedAllTransitions (state) {
			return Object.values(state.transitionLoads).indexOf(false) === -1
		}
	},

	mutations: {
    isTutoSeen (state, payload) {
      state.isTutoSeen = payload
      cookie.setItem('tuto_seen', payload)
    },
    sound (state, payload) {
      state.sound = payload
    },
		setRouteBeforeAbout (state, payload) {
			state.routeBeforeAbout = payload
		},
		pendingTransitionLoads (state, payload) {
			state.transitionLoads[payload.id] = payload.value
		}
	}
}