import { api } from '@/store/interfaces/apiCraft'
import data from '@/assets/data'
const { data : { aboutPages } } = data

const aboutKeys = Object.fromEntries(aboutPages.map(page => [page.slug, {}]))

export default {
  namespaced: true,

  state: {
    chapters: [],
    ...aboutKeys
  },

  getters: {
    chapters: state => {
      return state.chapters
    },
    chapter: state => slug => {
      return state.chapters.find(chapter => chapter.slug === slug)
    },
    episodes: state => {
      return state.chapters.reduce((acc, curr) => {
        acc.push(...curr.episodes)
        return acc
      }, [])
    },
    episode: (state, getters) => (chapterSlug, episodeSlug) => {
      const chapter = getters.chapter(chapterSlug)
      return chapter.episodes.find(episode => episode.slug === episodeSlug)
    },
    nextElement: (state, getters) => (chapterSlug, episodeSlug) => {
      const chapter = getters.chapter(chapterSlug)

      // At chapter
      // we get first episode
      if (!episodeSlug) {
        return {
          chapter: chapter,
          episode: chapter.episodes[0]
        }
      }

      const episode = getters.episode(chapterSlug, episodeSlug)

      // If there is another episode
      let nextEpisode = chapter.episodes[episode.index + 1]
      if (nextEpisode) {
        return {
          chapter: chapter,
          episode: nextEpisode
        }
      }

      // if there is no episode but another chapter
      let nextChapter = state.chapters[chapter.index + 1]
      if (nextChapter) {
        return {
          chapter: state.chapters[chapter.index + 1],
          episode: null
        }
      }

      return null
    },
    nextElementSlug: (state, getters) => (chapterSlug, episodeSlug) => {
      const element = getters.nextElement(chapterSlug, episodeSlug)

      return element?.episode ? element?.episode?.slug : element?.chapter?.slug
    },
    nextEpisode: (state, getters) => (chapterSlug, episodeSlug) => {
      const chapter = getters.chapter(chapterSlug)
      const episode = getters.episode(chapterSlug, episodeSlug)

      let next = chapter.episodes[episode.index + 1]
      if (!next) {
        next = state.chapters[chapter.index + 1] ? state.chapters[chapter.index + 1].episodes[0] : null
      }

      return next
    },
    loaded: state =>{
      return state.loaded
    },
    chapterIndex: (state, getters) => (chapterSlug) => {
      return getters.chapters.findIndex(chapter => chapter.slug === chapterSlug)
    }
  },

  actions: {
    chapters ({ commit, state }) {
      return new Promise(resolve => {
        const { request, cache } = api.getChapters()
        // cache.then(res => {
        //   // if (res !== null) {
        //     commit('chapters', formatChapter(res))
        //   //   commit('loaded', true)
        //   // }
        // })

        request.then(res => {
          commit('chapters', formatChapter(res))
          commit('loaded', true)
          resolve()
        })
      })
    },
    genericAboutPage ({ commit, state }, name) {
      return new Promise(resolve => {
        const { request, cache } = api.getGenericAboutPage(name)
        // cache.then(res => {
        //   // if (res !== null) {
        //     commit('chapters', formatChapter(res))
        //   //   commit('loaded', true)
        //   // }
        // })

        request.then(res => {
          commit('genericAbout', { name, data: res })
          resolve()
        })
      })
    }
  },

  mutations: {
    loaded (state, payload) {
      state.loaded = payload
    },
    chapters (state, payload) {
      state.chapters = payload
    },
    genericAbout (state, { name, data }) {
      state[name] = data
    },
    completeEpisode (state, payload) {
      state.keypoints[payload.chapter].keypoints[payload.episode].isComplete = true
    }
  }
}

function formatChapter (chapters) {
  let globalEpisodeIndex = 0
  chapters.forEach((chapter, i) => {
    chapter.episodes.forEach((episode, j) => {
      episode.index = j
    })
    chapter.index = i
  })

  return chapters
}