
export const config = `
  id
  title
  videoLoop: bouyguesVideoLoop {
    id
    url
  }
  videoTransition: bouyguesVideoTransition {
    id
    url
  }
  sound: bouyguesAudio {
    id
    url
  }
  ambientSound: bouyguesAmbientSound {
    url
    id
  }
`

export const homepage = `
  id
  title
  subtitle: sousTitre
  CTALabel
  image {
    ... on default_Asset {
      id
      url
      alt
    }
  }
  multipleTexts {
    ... on multipleTexts_multipleTexts_BlockType {
      id
      text
    }
  }
  position {
    x
    y
    z
  }
  look {
    x
    y
    z
  }
  video {
    ... on default_Asset {
      id
      url
      alt
    }
  }
`

export const chapter = `
  id
  slug
  title
  videoLoop: bouyguesVideoLoop {
    id
    url
  }
  videoTransition: bouyguesVideoTransition {
    id
    url
  }
  sound: bouyguesAudio {
    id
    url
  }
`

export const episode = `
  id
  title
  slug
  bouyguesAudio {
    url
  }
  bouyguesFigure
  bouyguesFigureUnit
  bouyguesImportantImage {
    id
    url
  }
  bouyguesImage {
    id
    url
  }
  bouyguesImportantText
  bouyguesImportantTitle
  bouyguesIndex {
    col1
    col2
  }
  bouyguesIndexes {
    ... on bouyguesIndexes_index_BlockType {
      id
      text
      number
      picto {
        id
        url
      }
    }
  }
  bouyguesOverTitle
  bouyguesOverTitleColor
  bouyguesSubTitle
  bouyguesTexts {
    ... on bouyguesTexts_paragraph_BlockType {
        paragraph
    }
  }
  videoLoop: bouyguesVideoLoop {
    id
    url
  }
  videoTransition: bouyguesVideoTransition {
    id
    url
  }
  sound: bouyguesAudio {
    id
    url
  }
  thumbnail: bouyguesThumbnail {
    url
    id
  }
`

export const endPage = `
  id
  title
  subtitle: sousTitre
  image {
      ... on default_Asset {
      id
      url
      alt
      }
  }
  CTA {
    text
    url
  }
  wysiwyg
`