<template>
  <div class="header u-row u-between u-pad-lg u-pad-y-sm--md">
    <router-link :to="{name: 'Introduction'}" class="u-w1of12 u-w2of12--md u-block">
      <img @click="$ga.send('click', 'Logo', 'Click sur le logo')" src="/images/logo.png" class="u-block u-w12of12 u-w9of12--md">
    </router-link>
    <div v-show="!($route.params.chapter && !$route.params.episode)" class="u-row u-middle header__links" :class="{'u-transparent': !menuElementsVisible}">
      <router-link :to="{ 'name': 'About' }" class="t-text u-marg-r-sm u-black">
        <app-button-square :transparent="true" svg="info" />
      </router-link>
      <app-button-square v-show="$device.isTablet && !($device.isIphone || $device.isIpad)" @click="toggleFullScreen" :grey="true" :svg="isFullScreen ? 'exit-full-screen' : 'enter-full-screen'" class="u-fill-white u-marg-r-sm" />
      <app-button-square @click="openMenu" :transparent="true" svg="zoom" />
    </div>
  </div>
</template>

<script>
import AppButtonSquare from '@/components/common/AppButtonSquare'

export default {
  emits: ['openNavigation', 'toggleFullScreen'],
  components: {
    AppButtonSquare,
  },
  data () {
    return {
      isFullScreen: false,
      isTransitioning: false,
    }
  },
  computed: {
    menuElementsVisible () {
      return !this.isTransitioning || this.$route.name === 'End'
    },
  },
  methods: {
    toggleFullScreen () {
      this.isFullScreen = !this.isFullScreen
      this.$emit('toggleFullScreen')
    },
    openMenu () {
      this.$ga.send('click', 'Menu navigation', 'Click sur le bouton menu')
      this.$emit('openNavigation')
    },
  },
  mounted () {
    this.$hub.on('transition:will', () => {
      this.isTransitioning = true
    })
    this.$hub.on('transition:end', () => {
      this.isTransitioning = false
    })
  }
}

</script>

<style lang="stylus">
.header
  position absolute
  top 0
  left 0
  width 100%
  &__links
    transition opacity 0.2s linear
</style>