<template>
  <div class="u-wrapper-panel chapter u-flex--sm u-bottom--sm" :class="{'u-transparent': isTransitioning}">

    <info-panel v-if="episode && !isTransition" :data="episode" />

    <progress-bar class="u-z-back"/>
    <video-controls :isNext="isNextAvailable" @onNext="onNext" :buttonLabel="!episode ? 'DISCOVER' : null"/>
    <!-- <markers-wrapper v-if="!episode" class="u-transparent" :class="{'chapter__markers--visible': markersVisible}" /> -->
  </div>
</template>

<script>
import PlayerPage from './PlayerPage'
import InfoPanel from '@/components/InfoPanel'
import VideoControls from '@/components/VideoControls'
import ProgressBar from '@/components/ProgressBar'
// import MarkersWrapper from '@/components/MarkersWrapper'

export default {
  mixins: [PlayerPage],

  components: {
    InfoPanel,
    ProgressBar,
    VideoControls,
    // MarkersWrapper
  },

  data () {
    return {
      isTransitioning: false,
      markersVisible: false
    }
  },

  computed: {
    episode () {
      return this.$store.getters['data/episode'](this.$route.params.chapter, this.$route.params.episode)
    },
    chapter () {
      return this.$store.getters['data/chapter'](this.$route.params.chapter)
    },
    sound () {
      if (this.$route.params.episode) {
        return this.episode.sound.length ? this.episode.sound[0].url : null
      }
      return this.chapter?.sound.length ? this.chapter.sound[0].url : null
    },
    nextSound () {
      const { chapter = null, episode = null } = this.$store.getters['data/nextElement'](this.$route.params.chapter, this.$route.params.episode) ?? {}
      
      return episode?.sound.length ? episode.sound[0].url : chapter?.sound.length ? chapter.sound[0].url : null
    },
    target () {
      const next = this.$store.getters['data/nextElement'](this.$route.params.chapter, this.$route.params.episode)

      if (!next) {
        return this.$local('End')
      }
      else if (!next.episode){
        return {...this.$local('Chapter'), params: {
          chapter: next.chapter.slug
        }}
      }

      return {...this.$local('Episode'), params: {
        chapter: next.chapter.slug,
        episode: next.episode.slug
      }}
    }
  },
  mounted () {
    this.$hub.on('transition:will', () => {
      this.isTransitioning = true
    })
    this.$hub.on('transition:end', () => {
      this.isTransitioning = false
    })
    setTimeout(() => {
      this.markersVisible = true
    }, 3000)
  },
  watch: {
    $route () {
      this.$ga.send('load', 'episode', this.$route.fullPath)
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'

.chapter
  transition: opacity .2s linear
+mq($until: 'desktop')
  .info-panel:not(.info-panel--full)
    margin-top: auto
    margin-bottom: $spacings.sm
    height: 120px
    width: 31.5% !important
    margin-left: 2.5% !important

.chapter__markers--visible
  opacity: 1 !important
</style>
