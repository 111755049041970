<template>
  <div @click="onToggle" class="sound u-row u-between u-cursor-pointer">
    <div
      v-for="bar in bars"
      class="sound__bar u-bg-white"
      :style="{ width: `calc(${100/bars.length}% - ${marg}px`, transform: `scaleY(${bar}) translateZ(0)`, transitionDuration: duration + 's' 
      }"
    />
  </div>
</template>

<script>
import { randomRange } from '@/utils/Maths'

export default {
  data () {
    return {
      bars: [0, 0, 0, 0],
      duration: 0
    }
  },

  computed: {
    isActive () {
      return this.$store.state.global.sound
    },
    marg () {
      return this.$device.isdesktop ? 3 : 2
    }
  },

  mounted () {
    this.randomBars()
  },

  methods: {
    randomBars () {
      if (this.isActive) {
        this.bars.forEach((bar, index) => {
          this.bars[index] = randomRange(.2, 1)
        })
        this.duration = randomRange(.5, 1)
        setTimeout(this.randomBars, this.duration * 1000)
      }
      else {
        this.bars.forEach((bar, index) => {
          this.bars[index] = .2
        })
        this.duration = .5
      }
    },
    onToggle () {
      this.$store.commit('global/sound', !this.isActive)
    }
  },

  watch: { isActive: 'randomBars' }

}
</script>

<style lang="stylus">
@import '~@/styles/settings/easings'

.sound
  height 35px

  &__bar
    border-radius 5px
    transition transform ease-in-out-quad
    transform-origin 50% 100%
    height 100%
</style>